<template>
  <full-screen-loading />
</template>
<script>
import FullScreenLoading from '@/components/common/FullScreenLoading.vue'

export default {
  name: 'Logout',
  components: { FullScreenLoading },
  mounted() {
    this.logoutUser()
  },
  methods: {
    async logoutUser() {
      await this.$store.dispatch('user/logoutUserFromOtherApp')
      this.$tabEvent.emit('logout-user')
      await this.$store.dispatch('user/logoutUserLocalOnly')
      // await this.$router.push('/')
      // await this.$auth.loginWithRedirect()
      if (process.env.VUE_APP_ENV === 'prod' && process.env.VUE_APP_SIMI_BLOG_URL) {
        window.location.replace(process.env.VUE_APP_SIMI_BLOG_URL)
      } else {
        await this.$router.push('/')
      }
    },
  },
}
</script>

<style scoped>

</style>
