<template>
  <div
    style="height: 100vh !important;"
    class="d-flex flex-column justify-content-center w-100 align-items-center"
  >
    <div class="loading">
      <div class="effect-1 effects" />
      <div class="effect-2 effects" />
      <div class="effect-3 effects" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullScreenLoading',
}
</script>

<style scoped>

</style>
